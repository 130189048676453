.mainmenu__button {
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 70px;

  @media #{$small-only} {
    height: auto;
  }
}

.hamburger {
  display: block;
  z-index: 999999;
  position: relative;
  transition: all 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;
}

.hamburger .line {
  width: 20px;
  height: 2px;
  background-color: #ffffff;
  display: block;
  margin: 4px auto;
  transition: all 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;
}

.mainmenu--is-fixed {
  .hamburger .line {
    background-color: #3f4ace;
  }
}

.hamburger {
  padding: 14px;
}

.hamburger.is-active .line:nth-child(2) {
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1) {
  opacity: 1;
  transform: translateY(6px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  opacity: 1;
  transform: translateY(-6px) rotate(-45deg);
}

.hamburger--label {
  color: #fff;
  text-transform: uppercase;
}

@media #{$medium-up} {
  .hamburger {
    display: none;
  }

  .hamburger--label {
    display: none;
  }
}

.mainmenu--is-fixed.nav__menu--mainmenu {
  background: #ffffff;
}

.nav__menu--mainmenu {
  background: transparent;
  transition: background 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;
}

.mainmenu {
  @media #{$large-up} {
    padding: 0px 40px;
  }

  @media #{$xlarge-up} {
    padding: 0px;
  }
}

.mainmenu__wrap {
  display: flex;
  justify-content: flex-start;

  /* transition: all 100ms;*/
  overflow: hidden;
  flex-direction: column;

  @media #{$medium-up} {
    flex-direction: row;
  }

  position: relative;
}

.mainmenu__list {
  position: relative;
  width: 100%;
  z-index: 99999;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;

  @media #{$small-up} {
    transform: translateX(0);
  }

  @media #{$medium-only} {
    width: auto;
  }

  @media #{$small-only} {
    width: 100%;
  }

  @media #{$medium-up} {
    height: 70px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: flex-end;
    align-items: center;
  }
}

.mainmenu__item {
  display: flex;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -moz-focus-inner: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.mainmenu__item .mainmenu__link {
  padding: 0 1.5rem;
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  position: relative;
  z-index: 4;
  cursor: pointer;
  align-items: center;
  transition: all 100ms;
  text-decoration: none;
  justify-content: center;
  transition: all 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;

  span {
    text-decoration: none;
    color: $archimedict-white;
    z-index: 3;
    white-space: nowrap;
    transition: all 0.2s cubic-bezier(0.345, 0, 0.25, 1) 0s;
  }

  &::before {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 0;
    width: calc(99% + 9px);
    height: 1px;
    background-color: #fff;
    content: '';
    border-right: solid 10px transparent;
    transform: translateX(-101%);
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }

  &::after {
    position: absolute;
    z-index: 2;
    bottom: 10px;
    left: 0;
    width: calc(99% + 9px);
    height: 1px;
    background-color: #fff;
    content: '';
    border-right: solid 10px transparent;
    transform: translateX(-101%);
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
  }

  &:hover::before,
  &:hover::after {

    @media #{$medium-up} {
      transform: translateX(0);
    }
  }
}

.mainmenu__list .mainmenu__item {
  @media #{$medium-up} {
    flex: 0 1 auto;
  }
}

.mainmenu__logo {
  @media #{$medium-up} {}

  height: auto;
}

.mainmenu__meta {
  width: 20px;
  height: 20px;
  margin-left: 60px;

  @media #{$medium-only} {
    position: absolute;
    top: 15px;
    right: 15px;
    margin: 0px;
  }
}

.mainmenu--is-fixed .tagname {
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
  transition: all 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;
}

.tagname img {
  vertical-align: middle;
  width: auto;

  @media #{$medium-only} {
    max-height: 50px;
    height: 50px;
  }

  @media #{$medium-up} {
    max-height: 40px;
    height: 40px;
  }

  @media #{$small-only} {
    max-height: 30px;
    height: 30px;
  }
}

.mainmenu--is-fixed {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;

  /*box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.35);*/
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08), 0 2px 2px 0 rgba(0, 0, 0, 0.08), 0 4px 4px 0 rgba(0, 0, 0, 0.08), 0 8px 8px 0 rgba(0, 0, 0, 0.08);
  margin-top: 0px;

  /*transition: all 100ms;*/

  .mainmenu__wrap {
    transition: all 100ms;
  }
}

.mainmenu.is-loaded .mainmenu__list {
  @media #{$small-up} {
    transform: translateX(100px);
  }
}

.mainmenu__item:hover .mainmenu__link span {
  color: $archimedict-white;
  transition: all 500ms;
}

.mainmenu__item.is-active:hover .mainmenu__link span {
  color: $archimedict-white;
  transition: all 500ms;
}

/* hover */

nav ul li:hover a {
  transition: all 500ms;
}

.mainmenu__link {
  position: relative;

  /*
  .tagname {
    @media #{$small-up} {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0%, -50%);
      transition: opacity 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;
      height: auto;
      opacity: 0;
      margin: 0;
    }
  }
  */

  .tagname img {
    margin: 10px 0 10px 20px;

    @media #{$medium-up} {
      margin: 10px 0 10px 20px;
    }
  }

  .tagname-white {
    opacity: 1;
  }

  .tagname-blue {
    display: none;
    opacity: 0;
  }
}

.mainmenu--is-fixed {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  background: #ffffff;
  margin-top: 0px;
  transition: background 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;

  .mainmenu__link span {
    color: $archimedict-blue;
  }

  .mainmenu__item:hover .mainmenu__link span {
    color: $archimedict-blue;
  }

  .mainmenu__wrap {
    transition: all 100ms;
  }

  .tagname-white {
    opacity: 0;
    display: none;
  }

  .tagname-blue {
    opacity: 1;
    display: block;
  }

  .hamburger--label {
    color: #3f4ace;
  }

  .mainmenu__item .mainmenu__link {
    &::before,
    &::after {
      background-color: #3f4ace;
    }
  }
}

.mobile-nav {
  .mainmenu__list {
    display: block;
  }

  .nav__menu--mainmenu {
    background: rgba(255, 255, 255, 1);
    height: 100vh;
    transition: background 0.33s cubic-bezier(0.345, 0, 0.25, 1) 0s;
  }

  .hamburger .line {
    background-color: #3f4ace;
  }

  .hamburger--label {
    color: #3f4ace;
  }

  .mainmenu__list {
    width: 90vw;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .mainmenu__item .mainmenu__link {
    padding: 0 0;

    &:hover::before,
    &:hover::after {
      transform: none;
    }
  }

  .mainmenu__item .mainmenu__link span {
    color: #3f4ace;
    transition: color 0.66s cubic-bezier(0.345, 0, 0.25, 1) 0s;
    border-bottom: solid 1px #3f4ace;
    width: 100%;
  }

  .tagname-white {
    opacity: 0;
    display: none;
  }

  .tagname-blue {
    opacity: 1;
    display: block;
  }

  .mainmenu__item {
    position: relative;
    left: -100%;
    z-index: 0;

    @for $i from 1 through 5 {
      &:nth-child( #{$i} ) {
        animation: slideIn 0.2s #{$i * 0.15}s cubic-bezier(0.345, 0, 0.25, 1) 1 forwards;
      }
    }
  }
}

@keyframes slideIn {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

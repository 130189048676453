.expertise__row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;

  @media #{$small-up} {
    flex-direction: column;
    margin-right: -10px;
    margin-left: -10px;
  }

  @media #{$medium-up} {
    flex-direction: row;
    margin-right: -20px;
    margin-left: -20px;
  }
}

.expertise__item {
  position: relative;
  height: auto;
  margin: 0px;
  text-align: left;
  cursor: pointer;
  flex-basis: 100%;
  max-width: 100%;

  @media #{$medium-up} {
    flex-basis: calc(100% / 3 * 1);
    max-width: calc(100% / 3 * 1);
  }

  @media #{$large-up} {}
}

.expertise__link {
  display: block;
  position: relative;
  cursor: pointer;

  @media #{$small-only} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media #{$medium-only} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .image {
    transition: 120ms all ease-in;
  }

  &:hover .expertise__title {
    color: $archimedict-blue;
    transition: 200ms transform ease-in;
  }

  &:hover .image {
    transform: scale(1.05);
  }
}

.expertise__image {
  overflow: hidden;
  margin: 0 auto 30px;

  @media #{$small-only} {
    min-width: 64px;
    margin: 0 30px 0 0;
  }

  @media #{$medium-only} {
    min-width: 64px;
    margin: 0 30px 0 0;
  }

  @media #{$small-up} {
    min-width: 72px;
    max-width: 82px;
  }
}

.section--expertise {
  .container {
    .container-header__title {
      display: block;
      margin: 30px 0;
      font-family: $font-family-primary;
      text-align: center;
      text-transform: none;

      @media #{$medium-only} {
        @include rem-calc(38);
      }

      @media #{$medium-up} {
        @include rem-calc(48);

        margin-bottom: 30px;
        text-transform: none;
        text-align: center;
      }

      @media #{$large-up} {}

      @media #{$xlarge-up} {}

      @media #{$small-only} {
        @include rem-calc(26);
      }
    }

    .container-header__body {
      @media #{$medium-up} {
        text-align: center;
        max-width: 60%;
        margin: auto;
      }
    }

    .container-header__body p {
      font-family: $font-family-text;
      text-align: center;

      @include rem-calc(16);

      margin-bottom: 20px;

      @media #{$small-up} {
        @include rem-calc(16);
      }
    }
  }
}

.expertise__body {
  margin: 0 0 30px 0;

  @media #{$small-up} {
    margin: 0 0 30px 0;
  }

  @media #{$medium-only} {}

  @media #{$medium-up} {
    margin: 0 auto 30px auto;
  }
}

.expertise__title {
  font-family: $font-family-primary;
  margin: 0 0 10px 0;
  text-align: left;

  @media #{$small-up} {
    @include rem-calc(26);

    text-align: left;
  }

  @media #{$medium-up} {
    text-align: center;
  }
}

.expertise__subtitle {
  text-align: left;
  font-family: $font-family-text;

  @include rem-calc(16);

  @media #{$small-up} {
    @include rem-calc(16);

    text-align: left;
  }

  @media #{$medium-up} {
    text-align: center;
    max-width: 74%;
    margin: auto;
  }
}

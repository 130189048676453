.container-header--large {
  @media #{$small-up} {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.container-header--large .container-header__body {
  p {
    color: #222;
    position: relative;

    @include rem-calc(14);

    font-family: $font-family-text;
    line-height: 1.5;
  }

  @media #{$small-up} {
    width: calc(100% / 5 * 2);

    p {
      @include rem-calc(16);
    }
  }

  @media #{$medium-up} {
    max-width: calc(100% / 3 * 1 - 30px);
    width: 100%;

    p {
      @include rem-calc(16);
    }
  }
}

.container-header--large .container-header__title {
  color: #222222;
  position: relative;
  font-family: $font-family-primary;
  line-height: 1.5;
  font-size: 1.5rem;
  margin: 0px;
  padding: 0px;
  letter-spacing: 1px;

  @media #{$small-up} {
    width: 100%;
    max-width: calc(100% / 3 * 2 + 30px);
  }

  @media #{$large-up} {
    font-size: 2rem;
  }

  &:before {
    background-color: transparent;
    content: "";
    display: none;
    height: 0px;
    width: 0px;
  }

  & > span {
    color: #fff;
    display: block;
    padding-left: 0px;
  }
}

.container-header--is-mirrored {
  .container-header__title {
    order: 2;
    text-align: left;
    margin-right: 0px;

    @media #{$xsmall-only} {
      font-size: 1.25rem;
    }

    @media #{$small-only} {
      margin-bottom: 10px;
    }

    @media #{$small-up} {
      text-align: right;
    }
  }

  .container-header__body {
    order: 1;
    text-align: left;

    @media #{$xsmall-only} {
      p {
        font-size: 1.1250rem;
      }
    }

    @media #{$small-only} {
      position: relative;
      background: transparent;
    }

    @media #{$large-up} {
      p {
        font-size: 1.1250rem;
      }
    }
  }
}

.container-header.is-centered {
  .container-header__title {
    @media #{$small-up} {
      max-width: 50%;
      width: 100%;
    }
  }

  .container-header__body {
    @media #{$small-up} {
      max-width: 50%;
      width: 100%;
    }

    @media #{$medium-up} {
      max-width: calc(100% / 4 * 2);
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
  }
}

.container-header--large.is-centered {
  @media #{$small-up} {
    flex-direction: column;
  }

  .container-header__title {
    display: inline-block;
    text-align: center;
    margin-right: 0px;
    margin-bottom: 20px;
    vertical-align: top;

    @media #{$medium-up} {
      max-width: calc(100% / 3 * 2);
      width: 100%;
    }
  }

  .container-header__title > span {
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
  }
}

.section--actionblock .is-centered {
  text-align: center;
}

@import "variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fz7tiz");
  src: url("#{$icomoon-font-path}/#{$icomoon-font-family}.eot?fz7tiz#iefix") format("embedded-opentype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fz7tiz") format("truetype"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.woff?fz7tiz") format("woff"),
  url("#{$icomoon-font-path}/#{$icomoon-font-family}.svg?fz7tiz##{$icomoon-font-family}") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: $archimedict-blue;
  margin-right: 20px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone {
  &:before {
    content: $icon-phone;
  }
}

.icon-mail {
  &:before {
    content: $icon-envelop;
  }
}

.icon-location {
  &:before {
    content: $icon-location;
  }
}

.icon-facebook {
  &:before {
    content: $icon-facebook_icon;
  }
}

.icon-instagram {
  &:before {
    content: $icon-instagram_icon;
  }
}

.icon-linkedin {
  &:before {
    content: $icon-linkedin_icon;
  }
}

.icon-twitter {
  &:before {
    content: $icon-twitter_icon;
  }
}

[class*="icon-svg-"] {
  display: inline-block;
  margin: 0 10px 0 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  &.knvb {
    width: 24px;
    height: 24px;
    background-image: url("/dist/images/svg/svg-logo-knvb.svg");
  }

  &.voetbal {
    width: 24px;
    height: 24px;
    background-image: url("/dist/images/svg/svg-logo-voetbal.svg");
  }

  &.sportlink {
    width: 48px;
    height: 24px;
    background-image: url("/dist/images/svg/emblemlogo-sportlink.svg");
  }

  &.meerovermedisch {
    width: 48px;
    height: 24px;
    background-image: url("/dist/images/svg/emblemlogo-meerovermedisch.svg");
  }
}

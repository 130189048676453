img,
embed,
object,
video {
  max-width: 100%;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(0);
  transition: transform 5s ease-in-out 0.5s;
}

figure {
  width: auto;
  height: auto;
  max-width: 100%;
}

.figure {
  position: relative;
}

picture:before {
  /*background: #111111;*/
}

picture {
  position: relative;
  display: block;
}

.picture--1-1::before {
  width: 100%;
  height: 0;
  padding-bottom: calc(1 / 1 * 100%);
  display: block;
  content: '';
}

.picture--16-9 {
  overflow: hidden;
}

.picture--16-9::before {
  width: 100%;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
  display: block;
  content: '';
}

.picture--3-2::before {
  width: 100%;
  height: 0;
  padding-top: calc(2 / 3 * 100%);
  display: block;
  content: '';
}

.picture--3-4::before {
  width: 100%;
  height: 0;
  padding-top: calc(4 / 3 * 100%);
  display: block;
  content: '';
}

.image.lazyloaded {
  opacity: 1;
}

.image.lazyload {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

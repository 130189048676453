.section--highlighted.has-gradient {
  @media #{$medium-only} {
    background: linear-gradient(to right, #3f4ace 0, #3f4ace 50%, $section-grey 0, $section-grey);

    &.mirrored {
      background: linear-gradient(to left, #3f4ace 0, #3f4ace 50%, $section-grey 0, $section-grey);
    }
  }

  @media #{$medium-up} {
    background: linear-gradient(to right, #3f4ace 0, #3f4ace 50%, $section-grey 0, $section-grey);

    &.mirrored {
      background: linear-gradient(to left, #3f4ace 0, #3f4ace 50%, $section-grey 0, $section-grey);
    }
  }

  @media #{$small-only} {
    padding: 0 0;
    background: #fff !important;
  }
}

.highlighted.top {
  .highlighted__itemwrap {
    display: flex;
    justify-content: flex-start;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
    height: 100%;

    @media #{$medium-only} {
      flex-direction: row;
      flex-direction: row-reverse;
      align-items: center;
    }

    @media #{$medium-up} {
      flex-direction: row;
      flex-direction: row-reverse;
      align-items: center;
    }

    @media #{$large-up} {
      flex-direction: row;
      flex-direction: row-reverse;
      align-items: center;
    }

    @media #{$small-only} {
      flex-direction: column;
    }
  }

  .highlighted__image,
  .highlighted__body {
    @media #{$medium-only} {
      width: 50%;
    }

    @media #{$medium-up} {
      width: 50%;
    }

    @media #{$small-only} {
      width: 100%;
    }
  }

  .highlighted__body {
    @media #{$medium-only} {
      padding: 0 60px 0 0;
    }

    @media #{$medium-up} {
      padding: 0 60px 0 0;
    }

    @media #{$large-up} {
      padding: 0 90px 0 0;
    }

    @media #{$small-only} {
      background-color: #fff;
      padding: 0 20px;
    }
  }

  .highlighted__image {
    @media #{$medium-only} {
      padding: 0 0 0 60px;
    }

    @media #{$medium-up} {
      padding: 0 0 0 60px;
    }

    @media #{$xlarge-up} {
      padding: 0 0 0 90px;
    }

    @media #{$small-only} {
      padding: 40px;
      background-color: #3f4ace;
    }
  }
}

.highlighted.bottom {
  .highlighted__itemwrap {
    display: flex;
    justify-content: flex-start;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
    height: 100%;

    @media #{$medium-only} {
      flex-direction: row;
      align-items: center;
    }

    @media #{$medium-up} {
      flex-direction: row;
      align-items: center;
    }

    @media #{$large-up} {
      flex-direction: row;
      align-items: center;
    }

    @media #{$small-only} {
      flex-direction: column;
    }
  }

  .highlighted__image,
  .highlighted__body {
    @media #{$medium-only} {
      width: 50%;
    }

    @media #{$medium-up} {
      width: 50%;
    }

    @media #{$small-only} {
      width: 100%;
    }
  }

  .highlighted__body {
    @media #{$medium-only} {
      padding: 0 0 0 60px;
    }

    @media #{$medium-up} {
      padding: 0  0 0 90px;
    }

    @media #{$large-up} {
      padding: 0 0 0 90px;
    }

    @media #{$small-only} {
      background-color: #fff;
      padding: 0 20px;
    }
  }

  .highlighted__image {
    @media #{$medium-only} {
      padding: 0 60px 0 0;
    }

    @media #{$medium-up} {
      padding: 0  90px 0 0px;
    }

    @media #{$large-up} {
      padding: 0 90px 0 0px;
    }

    @media #{$small-only} {
      background-color: #3f4ace;
      padding: 40px;
    }
  }
}

.highlighted__item {
  position: relative;
  height: auto;
  margin: 0;
  margin: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.highlighted__title {
  font-family: $font-family-primary;
  text-align: left;

  @include rem-calc(22);

  margin: 30px 0;

  @media #{$small-up} {
    @include rem-calc(24);

    margin-bottom: 30px;
  }
}

.highlighted__list--item {
  font-family: $font-family-text;

  @include rem-calc(16);

  @media #{$small-up} {
    @include rem-calc(18);

    margin-bottom: 30px;
  }

  span:after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: $archimedict-blue;
    transition: width .3s;
  }
}

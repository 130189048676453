[class^="button"] {
  background: transparent;
  font-family: $font-family-secundary;
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  color: $archimedict-blue;
  border: 1px solid $archimedict-blue;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  &.button--fullwidth {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  &.button--arrow {
    border: none;
    display: block;
  }

  &.button--hollow span {
    background-color: #3f4ace;
    color: #fff;
    padding: .5rem 1.25rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    transform: translate(0px, 0px);
    transition: all 0.2s cubic-bezier(0.51, 0.01, 0.52, 0.99) 0s;

    @media #{$small-only} {
      @include rem-calc(14);
    }

    @media #{$small-up} {
      @include rem-calc(16);
    }

    @media #{$medium-up} {
      @include rem-calc(18);
    }

    @media #{$large-up} {
      @include rem-calc(22);
    }
  }

  &.button--hollow span::before {
    content: '';
    margin-right: 70px;
    position: relative;
    left: 0px;
    height: 17px;
    width: 24px;
    display: inline-block;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 24 17.28"%3E%3Cpath fill="%23fff" d="M15.46 0l-2.85 2.83 3.74 3.74H0v3.99h16.35l-3.88 3.89 2.84 2.83L24 8.57 15.46 0z"/%3E%3C/svg%3E%0A');
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto;
    transition: all 0.3s cubic-bezier(0.51, 0.01, 0.52, 0.99) 0s;

    @media #{$small-up} {
      height: 17px;
      width: 24px;
      margin-right: 140px;
    }
  }

  &.button--hollow:hover span {
    transform: translate(20px, 10px);
    transition: all 0.3s cubic-bezier(0.51, 0.01, 0.52, 0.99) 0s;
  }

  &.button--hollow:hover span::before {
    left: 10px;
    transition: all 0.3s cubic-bezier(0.51, 0.01, 0.52, 0.99) 0s;
  }

  &.button--arrow span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 0;
    position: relative;
    text-transform: none;
    color: #444;
    padding: 1rem 0;
  }

  &.button--arrow span::before {
    content: '';
    position: relative;
    left: 0px;
    height: 22px;
    width: 32px;
    display: inline-block;
    margin-right: 30px;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" viewBox="0 0 32 22"%3E%3Cpath fill="%233f4ace" d="M21.02 0l-2.84 2.83L24.35 9H0v3.99h24.35l-6.17 6.18L21.02 22 32 11 21.02 0z"/%3E%3C/svg%3E');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: all 0.3s cubic-bezier(0.51, 0.01, 0.52, 0.99) 0s;

    @media #{$small-up} {
      height: 22px;
      width: 32px;
      margin-right: 30px;
    }
  }

  &.button--arrow:hover span::before {
    left: 10px;
    transition: all 0.3s cubic-bezier(0.51, 0.01, 0.52, 0.99) 0s;
  }
}

.section--articlequeue .cover {
  position: absolute;
  top: -120px;
  right: 0;
  left: -40px;
  bottom: 0;
  width: 100vw;
  height: calc(100% + 240px);
  z-index: -1;
}

.section--projectsqueue .cover {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.has-dashed-arc::before,
.has-dashed-arc::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 200% 40vw;
  background-repeat: no-repeat;
  background-position-x: 50%;
}

.has-full-circle::before,
.has-full-circle::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.75s cubic-bezier(0.51, 0.01, 0.52, 0.99) 0s;
}

.section--articlequeue.has-circle-top {
  .inside.has-dashed-arc::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='1' /%3E%3C/svg%3E");
    background-size: 200% 43vw;

    @media #{$medium-only} {
      background-position-y: 120px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='3' /%3E%3C/svg%3E");
    }

    @media #{$medium-up} {
      background-position-y: 70px;
    }

    @media #{$small-only} {
      background-position-y: 90px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='5' /%3E%3C/svg%3E");
    }
  }

  .outside.has-dashed-arc::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='1' /%3E%3C/svg%3E");
    background-size: 200% 45vw;

    @media #{$medium-only} {
      background-position-y: 150px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='3' /%3E%3C/svg%3E");
    }

    @media #{$medium-up} {
      background-position-y: 140px;
    }

    @media #{$small-only} {
      background-position-y: 120px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='5' /%3E%3C/svg%3E");
    }
  }

  .outside.has-dashed-arc::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='1' /%3E%3C/svg%3E");
    background-size: 200% 45vw;

    @media #{$medium-only} {
      background-position-y: 180px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='3' /%3E%3C/svg%3E");
    }

    @media #{$medium-up} {
      background-position-y: 210px;
    }

    @media #{$small-only} {
      background-position-y: 150px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath d='M 0 500 Q 1280 -500 2560 500' fill='none' stroke='%23e8e8e8' stroke-width='5' /%3E%3C/svg%3E");
    }
  }
}

.section--articlequeue.has-circle-bottom {
  .outside.has-dashed-arc::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='none' d='M 0 -500 Q 1280 500 2560 -500' stroke='%23e8e8e8' stroke-width='1' /%3E%3C/svg%3E");
    background-position-y: -604px;
  }

  .inside.has-dashed-arc::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='none' d='M 0 -500 Q 1280 500 2560 -500' stroke='%23e8e8e8' stroke-width='1' /%3E%3C/svg%3E");
    background-position-y: -490px;
  }

  .outside.has-dashed-arc::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='none' d='M 0 -500 Q 1280 500 2560 -500' stroke='%23e8e8e8' stroke-width='1' /%3E%3C/svg%3E");
    background-position-y: -637px;
  }

  .inside.has-dashed-arc::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='none' d='M 0 -500 Q 1280 500 2560 -500' stroke='%23e8e8e8' stroke-width='1' /%3E%3C/svg%3E");
    background-position-y: -437px;
  }
}

.section--projectsqueue {
  @media #{$medium-up} {
    .outside.has-full-circle::before {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 940px 940px;
    }

    .inside.has-full-circle::before {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 470px 470px;
    }

    .inside.has-full-circle::after {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 235px 235px;
    }

    .outside.has-full-circle::after {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 705px 705px;
    }
  }

  @media #{$large-up} {
    .outside.has-full-circle::before {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 1120px 1120px;
    }

    .inside.has-full-circle::before {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 560px 560px;
    }

    .inside.has-full-circle::after {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 280px 280px;
    }

    .outside.has-full-circle::after {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 840px 840px;
    }
  }

  @media #{$xlarge-up} {
    .outside.has-full-circle::before {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 1400px 1400px;
    }

    .inside.has-full-circle::before {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 700px 700px;
    }

    .inside.has-full-circle::after {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 350px 350px;
    }

    .outside.has-full-circle::after {
      background-image: url('data:image/svg+xml,%3Csvg viewBox="0 0 2560 2560" xmlns="http://www.w3.org/2000/svg"%3E%3Ccircle fill="none" stroke="%239d9d9d" stroke-width="1" cx="1279.5" cy="1279.5" r="1279.5"%3E%3C/circle%3E%3C/svg%3E');
      background-size: 1050px 1050px;
    }
  }
}

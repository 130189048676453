.footer {
  padding: 0 35px;

  @media #{small-up} {
    padding: 0 40px;
  }
}

.footernav {
  display: block;
  position: relative;
  background: #fff;

  @media #{small-up} {}

  @media #{$large-up} {}

  span {
    &::after {
      content: '';
      display: block;
      width: 0;
      height: 1px;
      background: #222;
      transition: width 200ms;
    }
  }
}

.footernav__wrap {
  display: block;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  z-index: 1;
  margin: 60px auto;

  @media #{$medium-up} {
    margin: 120px auto;
  }
}

.footernav__columnwrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media #{$small-up} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
  }

  @media #{$medium-up} {
    justify-content: space-between;
  }
}

.footernav__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;

  @media #{$small-up} {
    margin-bottom: 30px;
  }
}

.footernav__column {
  width: 100%;

  @media #{$small-up} {
    flex: 1 1 calc(100% / 3 * 1);
  }

  @media #{$xlarge-up} {
    flex: 1 1 calc(100% / 3 * 1);
  }

  @media #{$small-only} {
    &.footernav__column--site {
      display: none;
    }
  }

  @media #{$medium-only} {
    &.footernav__column--site {
      display: none;
    }

    &.footernav__column--network {
      flex-grow: 1;
    }
  }
}

.footernav__item {
  display: flex;

  /*flex: 1 1 calc(100% / 4 * 1);*/

  @media #{$small-only} {}

  @media #{$small-up} {
    width: 100%;
    float: none;
  }

  address {
    font-style: normal;
  }

  span {
    width: 100%;
    font-family: $font-family-text;

    @include rem-calc(14);

    @media #{$small-up} {
      @include rem-calc(16);
    }
  }

  /**
    &:hover span {
      color: #222;

      &::after {
        width: 100%;
      }
    }
    */
}

.footernav__title {
  margin-bottom: 10px;
  font-family: $font-family-primary;

  @media #{$small-up} {
    margin-bottom: 30px;
  }
}

.footernav__link {
  display: flex;
  align-items: baseline;

  i, address {
    display: inline-block;
  }

  i {
    position: relative;
  }
}

dialog {
  padding: 0;
  border: 0;
  border-radius: 0.6rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

/* native backdrop */
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.4);
}

/* polyfill backdrop */
dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

dialog[open] {
  animation: slide-up 0.4s ease-out;
}

/* The following styles are for older browsers when using
   the polyfill. These aren’t necessary for Chrome/Firefox. */
dialog {
  display: none;
  position: absolute;
  margin: 0 auto; /* should center it, but not working in Safari */
  max-width: 80vw;
  background-color: white;
}

dialog[open] {
  display: block;
}

/* prettying things up a bit */
.close {
  position: absolute;
  top: 0.1em;
  right: 0.2em;
  padding: 0.3em;
  line-height: 0.6;
  background-color: transparent;
  border: 0;
  font-size: 2em;
  color: #fff;

  &:focus {
    outline: 0;
  }
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 1em;
}

.modal-header {
  color: #fff;
  margin: 0;
  padding-bottom: 1em;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  border-bottom: 1px solid #fff;
  background-color: $archimedict-blue;
  padding: 1em 1em 1em 2em;

  @include rem-calc(14);

  @media #{$small-up} {
    @include rem-calc(18);
  }
}

.modal-body {
  @include rem-calc(14);

  @media #{$small-up} {
    @include rem-calc(16);
  }

  .modal-content {
    max-height: 50vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 2em 2em 2em 1em;

    h3 {
      @include rem-calc(14);

      @media #{$small-up} {
        @include rem-calc(18);
      }
      display:inline-block;
      padding-bottom: .6rem;
      border-bottom: 1px solid #000;
      margin: 1.2rem 0 .6rem 0;
    }
    p{
      padding-bottom: .6rem;
    }
    a{
      border-bottom: 1px solid #000;
      &:focus{
        outline:0;
      }
    }
  }
}

/* misc page styles -- not important for demo */

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translate(0, 15px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

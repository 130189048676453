.section--opensource {
  background-color: #ffffff;

  .opensource__imagerow {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: center;

    @media #{$small-up} {
      max-width: 50%;
    }

    figure {
      position: relative;
      height: auto;
      margin: 0px;
      text-align: left;
      cursor: default;
      width: 100%;

      @media #{$small-up} {
        flex-basis: calc(100% / 3 * 1);
        max-width: calc(100% / 3 * 1);
      }
    }

    margin: 0 auto 30px auto;
  }

  .opensource__image {
    width: 100%;

    .svg-image {
      margin: auto;
      display: block;
      width: 50%;

      @media #{$small-up} {
        width: 33%;
      }
    }
  }

  .container {
    .opensource-header {
      display: block;
      width: 100%;
      margin: auto;

      .opensource-header__title {
        display: block;
        margin-bottom: 30px;
        font-family: $font-family-primary;
        text-transform: none;
        text-align: left;

        @include rem-calc(26);

        @media #{$small-up} {
          @include rem-calc(38);

          margin-bottom: 30px;
          text-transform: none;
          text-align: left;
        }

        @media #{$medium-up} {
          text-align: center;
        }
      }
    }

    .opensource__body {
      .opensource__summary {
        @include rem-calc(14);

        font-family: $font-family-text;
        text-align: left;

        @media #{$small-up} {
          @include rem-calc(16);

          text-align: left;
        }

        @media #{$medium-up} {
          text-align: center;
        }
      }

      width: 100%;
      margin: auto;

      @media #{$small-up} {
        max-width: calc(100% / 4 * 2);
      }
    }
  }
}

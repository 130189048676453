/*@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700,800,900&display=swap");*/
@include font-face(muli-extra-bold, "/dist/fonts/muli/Muli-ExtraBold", 700, normal, eot woff2 woff);
@include font-face(muli-regular, "/dist/fonts/muli/Muli-Regular", 400, normal, eot woff2 woff);


$font-family-primary: "muli-extra-bold", sans-serif;
$font-family-secundary: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
$font-family-text: "muli-regular", sans-serif;

$base-font-size: 16;
$base-line-height: 1.5;

/* https://www.ninjaunits.com/converters/pixels/pixels-rem/ */

@mixin rem-calc($pixels) {
  font-size: $pixels / $base-font-size + rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-primary;
  font-size: unset;
  margin: unset;
  line-height: 1.25;
}

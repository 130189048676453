.navigation {
  color: #222222;
  opacity: 1;
  position: relative;
  z-index: 999;
}

.nav__wrap {
  margin: 0 auto;
  width: 100%;

  /*max-width: 1200px;*/


  @media #{$medium-only} {

  }

  @media #{$small-only} {

  }
}

.nav__wrap--large {
  max-width: 1400px;
}

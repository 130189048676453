.section--projectsqueue {
  background-color: #e8e8e8;
}

.section--projectsqueue .container-header {
  @media #{$small-up} {
    max-width: calc(100% / 2 - 2vw);
  }

  .container-header__title {
    margin-bottom: 30px;
  }
}

.projectsqueue {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;

  @media #{$medium-up} {
    margin-top: -100px;
  }

  .swiper-container-projects {
    @media #{$medium-up} {
      overflow: visible;
    }
  }
}

.projectsqueue__row {
  @media #{$medium-up} {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
  }
}

.projectsqueue__item {
  position: relative;
  height: auto;
  text-align: left;

  figure {
    margin: 0 auto 30px;
    overflow: hidden;
  }

  @media #{$medium-up} {
    padding: 0 2vw;
    flex-basis: calc(100% / 2 * 1 - 2vw);
    max-width: calc(100% / 2 * 1 - 2vw);
    margin-bottom: -60px;

    &:nth-of-type(odd) {
      margin-top: 120px;
    }

    &:nth-of-type(even) {
      margin-top: 0;
    }
  }
}

.projectsqueue__link {
  display: block;
  position: relative;
}

.projectsqueue__image {
  position: relative;
  overflow: hidden;
  background: transparent;
}

.projectsqueue__body {
  @media #{$small-up} {
    margin: 20px 0 0;
    padding: 0px;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.projectsqueue__title {
  color: #222222;
  outline: none;
  margin: 0;
  line-height: 1.5;
  font-family: $font-family-text;
  font-weight: 400;

  @include rem-calc(13);

  @media #{$small-up} {
    @include rem-calc(16);
  }
}

.projectsqueue__category {
  color: $archimedict-blue;
  margin: 0 0 5px;
  text-align: left;
  font-weight: 700;

  @include rem-calc(14);

  @media #{$small-up} {
    @include rem-calc(16);
  }

  .projectsqueue__category--title {
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }
  }
}

.projectsqueue__meta {
  display: block;

  @include rem-calc(16);

  font-family: $font-family-secundary;
  color: #222222;
}

.projectsqueue__item.is-knvb-orange figure:before {
  background: #f36d25;
}

.projectsqueue__item.is-knvb-orange img {
  @media #{$small-up} {
    left: -20px;
  }
}

.projectsqueue__item.is-voetbal-green figure:before {
  background: #0b9c49;
}

.projectsqueue__item.is-mom-blue figure:before {
  background: #0364b6;
}

.projectsqueue__item.is-mom-blue img {
  @media #{$small-up} {
    left: 30px;
  }
}

.projectsqueue__item.is-sportlink-orange figure:before {
  background: #f36c20;
}

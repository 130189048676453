.servicesdetails {}

.servicesdetails__wrap {
  padding: 20px 0px;
}

.servicesdetails__title {
  margin-bottom: 15px;
  font-weight: 700;
  letter-spacing: 1px;
}

.servicesdetails__numbers {
  margin-bottom: 5px;
}

.servicesdetails__numbers span {
  padding-right: 5px;
}

.servicesdetails {
  @media #{$small-only} {
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-out;
  }
}

.is-open .servicesdetails {
  @media #{$small-only} {
    border-bottom: 1px solid rgb(204, 204, 204);
  }
}

.container-xsmall,
.container-small,
.container-medium,
.container-large,
.container-xlarge {
  display: block;
  margin: 20px 0;
}

@media #{$medium-up} {
  .container-xsmall {
    flex-basis: calc(100% / 4 * 1);
    max-width: calc(100% / 4 * 1);
    margin: 0px;
  }

  .container-small {
    flex-basis: calc(100% / 3 * 1);
    max-width: calc(100% / 3 * 1);
    margin: 0px;
  }

  .container-medium {
    flex-basis: calc(100% / 2 * 1);
    max-width: calc(100% / 2 * 1);
    margin: 0px;
  }

  .container-large {
    flex-basis: calc(100% / 3 * 2);
    max-width: calc(100% / 3 * 2);
    margin: 0px;
  }

  .container-xlarge {
    flex-basis: calc(100% / 4 * 3);
    max-width: calc(100% / 4 * 3);
    margin: 0px;
  }
}

.has-multiplecontainers .section__wrap {
  display: flex;
  flex-direction: column;

  @media #{$small-up} {
    flex-direction: row;
  }
}

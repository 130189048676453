.expertisequeue__row {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;

  &--has-multiple {
    @media #{$small-up} {
      margin-bottom: 40px;
    }
  }

  @media #{$small-up} {
    flex-direction: row;
    margin-right: -20px;
    margin-left: -20px;
  }

  @media #{$large-up} {
    flex-direction: row;
    margin-right: -40px;
    margin-left: -40px;
  }
}

.expertisequeue__item {
  position: relative;
  height: auto;
  margin: 0px;
  padding: 0 0 20px;
  text-align: left;
  cursor: pointer;

  figure {
    max-width: calc(100% / 5 * 1);
    margin: 0 auto 30px;
    overflow: hidden;

    @media #{$small-only} {
      margin: 0 auto;
      max-width: calc(100% / 5 * 4);
    }
  }

  @media #{$small-up} {
    flex-basis: calc(100% / 3 * 1);
    max-width: calc(100% / 3 * 1);
    padding: 0 20px;
  }

  @media #{$large-up} {
    padding: 0 40px;
  }
}

.expertisequeue__nolink {
  display: block;
  position: relative;
  cursor: default;

  @media #{$small-only} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .image {
    transition: 120ms all ease-in;
  }

  &:hover .expertisequeue__title {
    transition: 200ms transform ease-in;
  }
}

.expertisequeue__image {
  position: relative;
  overflow: hidden;

  @media #{$small-only} {
    flex-basis: calc(100% / 10 * 2);
    max-width: calc(100% / 10 * 2);
  }
}

.expertisequeue__body {
  padding-left: 15px;
  flex-basis: calc(100% / 10 * 8);
  max-width: calc(100% / 10 * 8);

  @media #{$small-up} {
    margin: 20px 0 0;
    padding: 0px;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.expertisequeue__title {
  color: #222222;
  outline: none;
  margin: 0;
  font-family: $font-family-primary;
  text-align: left;

  @include rem-calc(14);

  line-height: 1.5;
  transition: 200ms color ease-in;

  @media #{$small-up} {
    margin-bottom: .75rem;
    text-align: center;
  }

  @media #{$large-up} {
    @include rem-calc(21);
  }
}

.expertisequeue__text {
  font-family: $font-family-text;
  color: #222222;
  text-align: left;

  @include rem-calc(14);

  @media #{$small-up} {
    text-align: center;

    @include rem-calc(16);
  }
}

.expertisequeue__category {
  margin: 0 0 5px;

  @include rem-calc(12);

  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 700;

  @media #{xsmall-up} {
    display: none;
  }

  @media #{$small-up} {
    margin: 0 0 10px;
  }

  @media #{$medium-up} {
    @include rem-calc(14);
  }
}

.expertisequeue__meta {
  display: block;

  @include rem-calc(16);

  font-family: $font-family-secundary;
  color: #222222;
  font-weight: 700;

  @media #{$small-only} {
    display: none;
  }

  @media #{$small-up} {
    display: block;
  }
}

.expertisequeue__icon {
  position: absolute;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 20px;
  bottom: 20px;
  z-index: 99999;
  width: 25px;
  height: 25px;
  display: block;
  transition: background .25s ease-in-out;
  background: #ffffff;
  color: #222222;

  @media #{$small-only} {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    left: 0px;
    bottom: 0px;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) rotate(0deg);
    margin: 0px;
    padding: 0px;

    @media #{$small-up} {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  @media #{$large-up} {
    display: block;
    width: 40px;
    height: 40px;
  }
}

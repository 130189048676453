section.section--hero {
  background: $archimedict-blue;
  background: -webkit-linear-gradient(to right, $left-gradient-blue, $right-gradient-blue);
  background: linear-gradient(to right, $left-gradient-blue, $right-gradient-blue);
  padding: 0px;

  @media #{$small-only} {
    padding: 0px;
  }
}

.section--hero .container {
  margin: 0px;
  max-width: 100%;
}

.section.section--hero .container-main {
  padding-bottom: 0px;
}

.section--hero .section__wrap {
  max-width: 1400px;
  margin: 0px auto;
}

.hero__layer {
  overflow: hidden;
  position: absolute;
  width: 100%;

  @media #{$small-only} {
    position: relative;
  }

  @media #{$small-up} {
    min-height: inherit;
  }
}

.hero__layer--image {
  display: flex;
  z-index: 2;

  @media #{$small-up} {
    padding: 0 30px;
  }

  @media #{$large-up} {
    padding: 0 40px;
  }
}

.hero__layer--body {
  display: flex;
  z-index: 3;

  @media #{$small-up} {
    padding: 0 30px;
  }

  @media #{$large-up} {
    padding: 0 40px;
  }
}

.hero__layer--background {
  z-index: 1;

  @media #{$small-only} {
    position: relative;
  }

  .hero__backgroundimage {
    min-height: inherit;
  }
}

.hero__layer--background figure {
  min-height: inherit;
}

.hero__wrap {
  width: 100%;

  @media #{$small-up} {
    max-width: 1200px;
    margin: 0 auto;
  }

  @media #{$xlarge-up} {
    max-width: 1800px;
  }
}

.hero__title {
  font-family: $font-family-primary;
  margin-bottom: 5px;
  text-align: left;
  color: #fff;

  @media #{$small-only} {
    @include rem-calc(26);

    margin-bottom: 5px;
  }

  @media #{$medium-only} {
    margin-bottom: 5px;
  }

  @media #{$medium-up} {
    margin-bottom: 30px;
  }

  @media #{$small-up} {
    @include rem-calc(42);

    margin-bottom: 30px;
  }
}

.hero__subtitle {
  font-family: $font-family-text;
  color: #fff;

  @include rem-calc(14);

  @media #{$small-up} {
    @include rem-calc(16);

    margin-bottom: 30px;
  }
}

.hero__content {
  display: flex;
  min-height: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.hero__content {
  &--center-right {
    align-items: center;
    text-align: center;
    padding: 0 20px;
  }

  &--right {
    align-items: flex-end;
    text-align: right;
  }

  &--center-left {
    align-items: flex-start;
    text-align: left;
  }
}

.picture-background {
  display: flex;
  min-height: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.background-image {
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;

  @media #{$small-only} {
    position: relative;
  }
}

.hero__image {
  width: 100%;

  @media #{$small-up} {
    width: 50%;
    max-width: 350px;
  }

  @media #{$medium-up} {
    &--medium {
      max-width: 500px;
    }

    &--large {
      max-width: 800px;
    }
  }

  @media only screen and (min-width: 768px) {
    .hero .image {
      transition: all 200ms ease-in;
    }
  }
}

.hero__body {
  padding: 40px 0px;
  width: 100%;

  @media #{$small-only} {
    padding: 30px 20px;
  }

  @media #{$small-up} {
    width: 50%;
    max-width: 400px;
  }

  @media #{$medium-up} {
    max-width: 600px;
  }

  &--center {
    text-align: center;
  }

  &--textalign-left {
    text-align: left;
  }

  &--textalign-right {
    text-align: right;
  }

  &--textalign-center {
    text-align: center;
  }
}

.hero {
  margin: 120px 0 60px 0;

  @media #{$small-up} {
    margin: 60px 0;
    min-height: 80vh;
  }
}

.hero__text {
  p {
    margin-bottom: 40px;

    @media #{$small-up} {}
  }
}

.hero__buttonwrap {
  display: block;
  position: relative;
  margin-top: 20px;

  @media #{$small-only} {
    width: 100%;
  }
}

.hero__content {
  @media #{$small-up} {
    &.hero__content {
      &--top-left {
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
      }

      &--top-center {
        align-items: center;
        justify-content: flex-start;
        text-align: center;
      }

      &--top-right {
        align-items: flex-end;
        justify-content: flex-start;
        text-align: right;
      }

      &--center-left {
        align-items: flex-start;
        justify-content: center;
        text-align: left;
      }

      &--center-center {
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      &--center-right {
        align-items: flex-end;
        justify-content: center;
        text-align: left;
      }

      &--bottom-left {
        align-items: flex-start;
        justify-content: flex-end;
        text-align: left;
      }

      &--bottom-center {
        align-items: center;
        justify-content: flex-end;
        text-align: center;
      }

      &--bottom-right {
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }
}

.hero.hero-fullheight {
  min-height: calc(100vh - 60px);

  @media #{$small-only} {
    min-height: calc(100vh - 220px);
  }
}

.hero figure {
  position: relative;
}

.projectsqueue__item figure:before,
.hero figure:before {
  content: "";
  width: 70%;
  height: 70%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #009e4c;
  z-index: -1;
}

.projectsqueue__item figure {
  position: relative;
}

.projectsqueue__item figure:after,
.hero figure:after {
  content: "";
  width: 90%;
  height: 90%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: 1px solid #fff;
  z-index: -2;
}

/*
@media #{$small-only} {
  .hero--withimage .hero__layer--background {
    display: none;
  }
}
*/

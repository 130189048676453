.footermenu {
  position: relative;
  display: block;

  @media #{$small-only} {
    padding: 0 20px;
  }

  @media #{$small-up} {
    height: 50px;
  }

  @media #{$large-up} {
    height: 60px;
  }
}

.footermenu__wrap {
  position: relative;
  transition: background 100ms ease-in;
  height: 100%;
  display: flex;
  align-items: center;

  @media #{$small-only} {
    max-height: 150px;
    padding: 20px 0px;
  }
}

.footermenu__item {
  display: flex;
  flex-basis: 20%;
  max-width: 20%;
  list-style: none;
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -moz-focus-inner: 0;
  flex: 1 1 auto;

  @media #{$small-only} {
    max-height: 20px;
    padding: 10px 0;
    flex: 1 1 50%;
    height: 100%;
  }

  @media #{$small-up} {
    -webkit-box-flex: 0;
    flex: 1 1 auto;
    flex-basis: 20%;
    max-width: 20%;
  }

  &:hover .footermenu__link span {
    color: $archimedict-blue;
  }
}

.footermenu__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  -moz-focus-inner: 0;
  border-left: 1px solid #999999;
  height: 100%;
  width: 100%;

  @media #{$small-only} {
    max-height: inherit;
    padding: 10px 0;
    flex-wrap: wrap;
  }
}

.footermenu__link {
  width: 100%;
  -webkit-font-smoothing: antialiased;
  position: relative;
  z-index: 4;
  cursor: pointer;
  transition: color 100ms;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  display: inline-flex;
  z-index: 3;

  span {
    font-family: $font-family-primary;
    letter-spacing: 1px;
    text-decoration: none;
    color: #222;
    z-index: 3;
    text-transform: none;
    transition: color .1s;
    text-transform: uppercase;
    white-space: nowrap;

    @media #{$small-only} {}

    @media #{$large-up} {}
  }
}

.footermenu__logo {
  @media #{$large-up} {
    width: 180px;
    height: 100%;
  }
}

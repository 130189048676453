ol.highlighted__list {
  list-style: none;
  padding: 0;
  counter-reset: ol-counter;
  width: 100%;

  li {
    counter-increment: ol-counter;
    margin: 0 0 0.5rem 0;
    display: flex;

    &::before {
      content: counter(ol-counter) ". ";
      color: $archimedict-blue;
      font-weight: bold;
      padding-right: 12px;
      white-space: nowrap;
    }
  }

  &.highlighted__list {
    li {
      &::before {
        content: "0" counter(ol-counter) "";

        @media #{$medium-only} {}

        @media #{$medium-up} {
          padding-right: 14px;
        }

        @media #{$small-only} {}
      }
    }
  }
}

ul.services__list {
  list-style: none;
  padding: 0;

  /*counter-reset: ul-counter;*/

  @include rem-calc(14);

  @media #{$small-up} {
    @include rem-calc(16);
  }

  li {
    /*counter-increment: ul-counter;*/
    margin: 0 0 0.5rem 0;
    display: flex;

    &::before {
      /*content: counter(ul-counter) ". ";*/
      content: '\25A0';
      color: $archimedict-blue;
      font-weight: bold;
      padding-right: .8rem;
    }
  }
}

ul.cookie__list {
  list-style: none;
  padding-bottom: .6rem;

  /*counter-reset: ul-counter;*/

  @include rem-calc(14);

  @media #{$small-up} {
    @include rem-calc(16);
  }

  li {
    /*counter-increment: ul-counter;*/
    margin: 0 0 0.5rem 0;
    display: flex;

    &::before {
      /*content: counter(ul-counter) ". ";*/
      content: '\25A0';
      color: $archimedict-blue;
      font-weight: bold;
      padding-right: .8rem;
    }
  }
}

/*@import url("https://fonts.googleapis.com/css?family=Muli:300,400,700,800,900&display=swap");*/

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html {
  font-size: 16px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  cursor: auto;
  font-family: $font-family-text;
  font-weight: 400;
  font-style: normal;
  line-height: $base-line-height;
  color: $text-color;
  background: #ffffff;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  @include rem-calc(16);

  &.mobile-nav {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.header,
.main,
.footer {
  position: relative;
  display: block;
}

.header {
  z-index: 99999;
}

.footer {
  z-index: 9999;
}

:focus {
  z-index: 9999;
}

aside,
main,
article,
section,
nav,
figure,
figcaption {
  display: block;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

figure {
  position: relative;
}

.main svg {
  height: 100%;
  overflow: visible;
}

.is-white {
  background: #ffffff;
}

.is-grey {
  background: #cccccc;
}

.text-is-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.mobile-only {
  display: none;
}

.has-multiplerows .row {
  margin-bottom: 40px;

  @media #{$small-only} {
    margin-bottom: 0px;
  }

  &:last-child {
    margin-bottom: 0px;
  }
}

a {
  color: $text-color;
}

.header {
  position: absolute;
  z-index: 9999999999;
  top: 0;
  width: 100%;
}

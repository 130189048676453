.has-square-mid {
  &::before {
    @media #{$small-up} {
      content:'';
      top: 0;
      left: 0;
      width: 100%;
      height: 50.1%;
      background-color: #ffffff;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath fill='rgba(241, 241, 241, 1.000)' d='M 0 500 Q 1280 -500 2560 500' /%3E%3C/svg%3E");
      background-size: 220% 100%;
      background-repeat: no-repeat;
      background-position-y: 0px;
      background-position-x: 50%;
    }
  }

  &::after {
    @media #{$small-up} {
      content:'';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50.1%;
      background-color: #ffffff;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='rgba(241, 241, 241, 1.000)' d='M 0 -500 Q 1280 500 2560 -500' /%3E%3C/svg%3E");
      background-size: 220% 100%;
      background-repeat: no-repeat;
      background-position-y: 0px;
      background-position-x: 50%;
    }
  }
}
.footer-copyright {
  padding: 0 20px;
  display: block;
  position: relative;
  overflow: hidden;
  background: #fff;

  @media #{$small-up} {
    padding: 20px 40px;
  }
}

.footer-copyright .wrap {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1400px;
  z-index: 1;
}

.footer-copyright .row {
  display: flex;
  align-items: center;
  padding: 1rem 0;

  @include rem-calc(12);

  @media #{$small-up} {
    padding: 1rem 0;

    @include rem-calc(14);
  }
}

.footer-copyright a,
.footer-copyright p,
.footer-copyright li {
  color: $text-color;
}

.footer-copyright a {
  transition: 200ms color ease-in;
}

.footer-copyright ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  li {
    padding-right: 10px;
    width: auto;
    display: flex;
    align-items: center;
  }
}

.footer-copyright ul li span {
  padding-right: 5px;
  font-weight: 400;
}

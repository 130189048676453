.arrow-down {
  opacity: 1;
  visibility: visible;
  width: 30px;
  height: 30px;
  background: transparent;
  position: relative;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);

  &:after {
    right: 10px;
    transform: rotate(-45deg);
  }
}

.arrow-down {
  @media #{$small-up} {
    opacity: 0;
    visibility: hidden;
    height: 0;
  }
}

.arrow-down.active {
  background: transparent;
}

.arrow-down:before,
.arrow-down:after {
  content: "";
  display: block;
  width: 8px;
  height: 2px;
  background: #3f4ace;
  position: absolute;
  top: 15px;
  transition: transform .5s;
}

.arrow-down:before {
  right: 14px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transform: rotate(45deg);
}

.collapsible.is-open .arrow-down:before {
  transform: rotate(-45deg);
}

.collapsible.is-open .arrow-down:after {
  transform: rotate(45deg);
}

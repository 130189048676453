.section--services {
  background-color: $archimedict-white;

  @media #{$small-only} {
    background-color: #e8e8e8;
  }
}

.services__row {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media #{$small-up} {
    flex-flow: nowrap;
  }

  @media #{$medium-only} {
    flex-flow: wrap;
  }

  @media #{$large-up} {
    flex-flow: nowrap;
  }
}

.services__item {
  display: block;
  position: relative;
  height: auto;
  margin: 0;
  text-align: left;
  width: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;

  .image {
    transition: 120ms all ease-in;
  }

  @media #{$medium-only} {
    flex-basis: calc(100% / 4 * 2 - 60px);
    max-width: calc(100% / 4 * 2 - 60px);
    margin-right: 60px;

    &:nth-of-type(even) {
      margin-right: 0;
    }
  }

  @media #{$medium-up} {
    flex-basis: calc(100% / 4 * 2 - 90px);
    max-width: calc(100% / 4 * 2 - 90px);
    margin-right: 90px;

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }

  @media #{$small-only} {
    flex-basis: calc(100% / 4 * 4);
    max-width: calc(100% / 4 * 4);
    margin-right: 0;
  }

  &:hover .services__title {
    @media #{$small-up} {
      transition: 200ms transform ease-in;
    }
  }
}

.services__itemwrap {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
}

.services__body {
  padding: 0;
}

.services__image {
  margin-right: 20px;
  padding: 0;
}

.section--services {
  .container {
    .container-header__title {
      margin: 0 auto 30px auto;
      text-transform: none;
      text-align: left;
      font-family: $font-family-primary;

      @include rem-calc(26);

      @media #{$small-up} {
        @include rem-calc(38);

        text-align: center;
      }
    }

    .container-header__body {
      width: 100%;
      margin: auto;
      @media #{$small-up} {
        max-width: calc(100% / 4 * 2);
      }
    }

    .container-header__body p {
      @include rem-calc(14);

      font-family: $font-family-text;
      text-align: left;

      @media #{$small-up} {
        @include rem-calc(16);

        text-align: center;
      }
    }


  }
}

.services__item {
  .services__title {
    @include rem-calc(16);

    font-family: $font-family-primary;

    @media #{$small-up} {
      @include rem-calc(22);
    }
  }

  .servicesdetails {
    @include rem-calc(16);

    font-family: $font-family-text;
    text-align: left;

    @media #{$small-up} {
      @include rem-calc(18);
    }

    .servicesdetails__numbers > div {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    /*
    .services__list {
      @media #{$small-up} {
        columns: 100px 2;
        column-gap: 0;
      }
    }
    */
  }
}

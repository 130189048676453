.section {
  padding: 0 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &.section--services {
    background-color: #ffffff;
  }

  &.section--callout {
    background-color: #e8e8e8;
  }
/*  &.section--expertisequeue{
    background-color: #f1f1f1;
  }
*/  &.section--clients{
    background-color: #f1f1f1;
  }
}

.section__wrap {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1400px;
  z-index: 1;

/*  .container{
    @media #{$medium-up} {
      min-height: 400px;
    }
  }
  */
}

.section--projectsqueue .section__wrap {
  max-width: 1200px;
}

.section__wrap--fullwidth {
  max-width: 100%;
}

/*
.section__wrap--large {
  max-width: 1600px;
}
*/

.section--contentpage-header .container-header {
  padding: 20px 0px;
}

.has-multiplecontainers .section__wrap {
  display: flex;
  flex-direction: column;
}

@media #{$small-up} {
  .has-multiplecontainers .section__wrap {
    flex-direction: row;
  }
}

@media #{$medium-only} {
  .section {
    padding: 0px 35px;
  }

  .section__wrap {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .has-circle-top,
  .has-circle-bottom {
    .section__wrap {
      &.section__wrap--fullwidth {
        /*margin-bottom: 0;*/
      }
    }
  }
}

@media #{$medium-up} {
  .section {
    padding: 0px 35px;
  }

  .section__wrap {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .has-circle-top,
  .has-circle-bottom {
    .section__wrap {
      &.section__wrap--fullwidth {
        margin-bottom: 0;
      }
    }
  }
}

@media #{$large-up} {
  .section {
    padding: 0px 40px;
  }

  .section__wrap {
    margin-top: 120px;
    margin-bottom: 120px;
  }

  .has-circle-top{
    .section__wrap {
      &.section__wrap--fullwidth {
        margin-bottom: 0;
      }
    }
  }
  .has-circle-bottom {
    .section__wrap {
      &.section__wrap--fullwidth {
        margin-bottom: 0;
        margin-top: 60px;
      }
    }
  }
}

@media #{$small-only} {
  .section {
    padding: 0px 30px;
  }

  .section__wrap {
    margin-top: 60px;
    margin-bottom: 60px;
  } 
}

.section.section--featured {
  padding: 0px;
}

.section.section--featured .section__wrap {
  margin: 0px;
  max-width: 100%;
}

.hero__layer--background {
  background: #6c0000;
}

.section p {
  letter-spacing: .01em;
}

section.is-light {
  background: #ffffff;
}

section.is-fixed {
  position: fixed;
  bottom: 0;
  z-index: 99999;
  width: 100vw;
}


.section.section--expertisequeue .section__wrap,
.section.section--highlighted.has-circle-top .section__wrap{
  @media #{$small-only} {
    margin-top: 90px;
  margin-bottom: 0px;
  }
}   

.section.section--hero {
  padding-bottom: 10%;
}

#werkzaamheden {
  margin-top: -15%;
}

.section--clients .section__wrap {
  max-width: 1200px;
}
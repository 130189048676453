.container-header,
.container-main,
.container-footer {
  display: block;
  position: relative;
}

.container-header,
.container-main {
  padding-bottom: 30px;
}

.section--quicklinks .container-main {
  padding-bottom: 0px;
}

.container-main--is-static {
  position: static;
}

.container-footer {
  text-align: center;
}

.container-header--large .container-header__title {
  font-weight: normal;
}

.container-header__title {
  line-height: 1.25;
  font-family: $font-family-primary;
  font-weight: normal;
  letter-spacing: 1px;
  margin: 0px;
}

.is-dark .container-header--large .container-header__body p {
  color: #fff;
}

.main svg {
  height: 100%;
  overflow: visible;
}

.is-white {
  background: #ffffff;
}

.has-multiplerows .row {
  margin-bottom: 40px;
}

.has-multiplerows .row:last-child {
  margin-bottom: 0px;
}

.container-footer .button.button--fullwidth {
  width: 100%;
  min-width: 100%;

  /*  max-width: 100%;*/
}

.container-main.container-main--only {
  padding-bottom: 0px;
}

@media #{$small-only} {
  .has-multiplerows .row {
    margin-bottom: 0px;
  }
}

@media #{$small-only} {
  .container-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
}

@media #{$small-up} {
  .container-header,
  .container-main {
    padding-bottom: 40px;
  }
}

.container-header__title {
  @include rem-calc(26);

  @media #{$small-up} {
    @include rem-calc(32);
  }
}

@media #{$large-up} {
  .container-header,
  .container-main {
    padding-bottom: 50px;
  }
}

.section.section--featured .container-main {
  padding: 0px;
}

.section-introblock .container-main {
  padding-top: 50px;
}

.section--expertisequeue .container-main,
.section--opensource .container-main,
.section--projectsqueue .container-main,
.section--services .container-main,
.section--clients .container-main,
.section--highlighted .container-main {
  padding-bottom: 0px;
}

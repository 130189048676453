section.has-circle-top::before,
section.has-circle-top::after,
section.has-circle-bottom::before,
section.has-circle-bottom::after {
  content: '';
  display: block;
  position: absolute;
  overflow-x: hidden;
  z-index: -1;
  background-repeat: no-repeat;
}

.section--news.has-circle-top::before,
.section--articlequeue.has-circle-top::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 40vw;
  background-color: #e8e8e8;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath fill='rgba(255, 255, 255, 1.000)' d='M 0 500 Q 1280 -500 2560 500' /%3E%3C/svg%3E");
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-position-x: 50%;
}

.section--articlequeue.has-circle-top::before{
  background-color: #ffffff!important;
  background-image: none;
}

.section--expertisequeue.has-circle-top::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
 /* background-color: #ffffff; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath fill='rgba(241, 241, 241, 1.000)' d='M 0 500 Q 1280 -500 2560 500' /%3E%3C/svg%3E");
  background-size: 220% 100%;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-position-x: 50%;
  @media #{$small-only} {
    background-size: 440% 100%;
  }
}

.section--highlighted.has-circle-top::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 50vw;
  background-color: #f1f1f1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath fill='rgba(63, 74, 205, 1.000)' d='M 0 500 Q 1280 -500 2560 500' /%3E%3C/svg%3E");
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-position-x: 50%;

  @media #{$small-up} {
    width: 50%;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #f1f1f1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath fill='rgba(241, 241, 241, 1.000)' d='M 0 500 Q 1280 -500 2560 500' /%3E%3C/svg%3E");
    background-size: 440% 100%;
    background-position-x: 35%;
    background-repeat: no-repeat;
    background-position-y: 0px;
  }
}

.section--highlighted.has-circle-top::after {
  @media #{$small-up} {
    width: 50%;
    right: 0;
    top: -1px;
    height: 100%;
    background-color: #f1f1f1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 2560 500'%3E%3Cpath fill='rgba(63, 74, 206, 1.000)' d='M 0 500 Q 1280 -500 2560 500' /%3E%3C/svg%3E");
    background-size: 440% 100%;
    background-position-x: 65%;
    background-repeat: no-repeat;
    background-position-y: 0px;
  }
}

.section--highlighted.has-circle-bottom::before {
  width: 50%;
  left: 0;
  bottom: 0;
  height: 100%;
  background-color: #f1f1f1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='rgba(63, 74, 206, 1.000)' d='M 0 -500 Q 1280 500 2560 -500' /%3E%3C/svg%3E");
  background-size: 440% 100%;
  background-position-x: 35%;
  background-position-y: 0px;

  @media #{$small-only} {
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: rgba(63, 74, 206, 1.000);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='rgba(255, 255, 255, 1.000)' d='M 0 -500 Q 1280 500 2560 -500' /%3E%3C/svg%3E");


    background-size: 100% 80%;
    background-repeat: no-repeat;
    background-position-y: 0px;
    background-position-x: 50%;
  }
}


.section--highlighted.has-circle-bottom::after {
  width: 50%;
  right: 0;
  bottom: 0;
  height: 100%;
  background-color: #f1f1f1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='rgba(241, 241, 241, 1.000)' d='M 0 -500 Q 1280 500 2560 -500' /%3E%3C/svg%3E");
  background-size: 440% 100%;
  background-position-x: 65%;
  background-position-y: 0px;

  @media #{$small-only} {
    width: 100%;
  right: 0;
  bottom: 0;
  height: 60px;
  background-color: #f1f1f1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='rgba(255, 255, 255, 1.000)' d='M 0 -500 Q 1280 500 2560 -500' /%3E%3C/svg%3E");
  background-size: 100% 100%;
  background-position-x: 50%;
  background-position-y: 0px;

  }
}

.section--clients.has-circle-bottom::before {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='rgba(241, 241, 241, 1.000)' d='M 0 -500 Q 1280 500 2560 -500' /%3E%3C/svg%3E");
  background-size: 220% 100%;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-position-x: 50%;
  @media #{$small-only} {
    background-size: 440% 100%;
  }
}

.section--news.has-circle-bottom::before,
.section--articlequeue.has-circle-bottom::before {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40vw;
  background-color: #f1f1f1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 -500 2560 500'%3E%3Cpath fill='rgba(241, 241, 241, 1.000)' d='M 0 -500 Q 1280 500 2560 -500' /%3E%3C/svg%3E");
  background-size: 200% 100%;
  background-repeat: no-repeat;
  background-position-y: 0px;
  background-position-x: 50%;
}


.section--articlequeue.has-circle-bottom::before{
  background-color: #ffffff!important;
  background-image: none;
}
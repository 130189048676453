$section-grey: #f1f1f1;
$section-white: #ffffff;

$archimedict-white: #ffffff;
$archimedict-blue: #3f4ace;
$archimedict-lightgrey: #f7f7f7;
$archimedict-darkgrey: #d6d6d6;

$archimedict-grey: #e8e8e8;

$archimedict-orange-left: #fc9309;
$archimedict-orange-right: #f77c19;

$archimedict-green-left: #09a770;
$archimedict-green-right: #049754;

$archimedict-blue-left: #70d3f3;
$archimedict-blue-right: #5fc9ec;

$archimedict-dark-blue-left: #86c4ee;
$archimedict-dark-blue-right: #3794d3;

$xs4all-yellow: #eebc40;
$photoshop-dark: #001d26;

$bg-color: #1370FB;
$dot-color: #001d26;

$dot-size: 4px;
$dot-space: 12px;

$text-color: #222;

$white: rgba(255, 255, 255, 1) !default;
$lightgray: rgba(246, 246, 246, 1) !default;
$darkgray: #e8e8e8 !default;


$left-gradient-blue: #3f4ace;
$right-gradient-blue: #1e3c72;
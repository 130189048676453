.notification {
  position: fixed;
  right: 1rem;
  left: 1rem;
  bottom: 1rem;
  z-index: 99999;
  background: $archimedict-blue;
  width: auto;
  max-width: 99vw;
  padding: 1rem;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
  transition: all 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.notification.is-active {
  opacity: 1;
  visibility: visible;
  transition-delay: 200ms;
  transition: all 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}

.notification__title {
  text-align: center;
}

.notification__link {
  text-decoration: underline;
  cursor: pointer;
}



.notification__button {
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  margin-left: 10px;
  transition: background 300ms ease-in-out;
  margin: 0 0 0 1rem;

  &.button-is-oke{
    background: #222222;
  }
  &.button-is-not-oke{
    background: red;
  }
}



.notification__text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media #{$medium-up} {
    flex-direction: row;
  }

.notification__response{
  display: flex;
  flex-direction: row;
}

}


.notification .close-button {
  display: none;
}

.notification__button:hover {
  background: #ffffff;
  color: #222222;
}

.notification__close .close-button {
  cursor: pointer;
}

.notification__close .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.notification__close .close-button .line {
  background-color: #222222;
  transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.notification__close .close-button:hover .line {
  background-color: #ffffff;
}

.notification__text a,
.notification__text p {
  color: #fff;
}

@media #{$medium-only} {
  .notification {
    padding: 2rem 1rem 2rem;
  }

  .notification__button {
    display: block;
    margin-top: 1rem;
  }
  .notification__button:first-child{
    margin-left: 0;
  }
}

.notification__text {
  @include rem-calc(14);

  @media #{$medium-up} {
    @include rem-calc(16);
  }
}

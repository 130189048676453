.section--articlequeue {
  background-color: #fff;
}

.articlequeue__row {
  display: flex;

  /*flex-direction: column;*/
  justify-content: flex-start;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;

  &--has-multiple {
    @media #{$small-up} {
      margin-bottom: 40px;
    }
  }

  &.has-nowrap {
    @media only screen and (max-width: 1600px) {
      display: flex;
      justify-content: flex-start;
      flex-flow: row nowrap;
      overflow-x: scroll;
      scroll-behavior: smooth;
    }
  }

  @media #{$small-up} {
    flex-direction: row;
  }

  @media #{$large-up} {
    flex-direction: row;
  }
}

.articlequeue__item {
  position: relative;
  height: auto;
  margin: 0px;
  padding: 0 0;
  text-align: left;
  flex-basis: calc(100% / 2 * 1);
  max-width: calc(100% / 2 * 1);
  flex: 0 0 auto;

  figure {
    max-width: calc(100% / 3 * 2);
    margin: 0 auto 15px;
    border-radius: 100%;
    overflow: hidden;

    @media #{$small-up} {
      margin: 0 auto 30px;
    }

    @media #{$xlarge-up} {
      max-width: 50%;
      margin: 0 auto 15px;
    }
  }

  @media #{$small-up} {
    flex-basis: calc(100% / 3 * 1);
    max-width: calc(100% / 3 * 1);
    padding: 0 1vw;
  }

  @media #{$medium-up} {
    padding: 0 2vw;
    flex-basis: calc(100% / 4 * 1);
    max-width: calc(100% / 4 * 1);
  }

  @media #{$xlarge-up} {
    padding: 0 2vw;
    flex-basis: calc(100% / 5 * 1);
    max-width: calc(100% / 5 * 1);
  }

  transition: transform 0.6s cubic-bezier(0.345, 0, 0.25, 1) 0s, opacity 0.2s cubic-bezier(0.345, 0, 0.25, 1) 0s;
}

.articlequeue__link {
  display: block;
  position: relative;
  cursor: default;

  @media #{$small-only} {
    /*
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    */
  }

  .image {
    transition: 120ms all ease-in;
  }

  &:hover .articlequeue__title {
    transition: 200ms transform ease-in;
  }

  &:hover .image {
    transform: scale(1.05);
  }
}

.articlequeue__image {
  position: relative;
  overflow: hidden;
  background: transparent;

  @media #{$small-only} {
    /*
    flex-basis: calc(100% / 10 * 3);
    max-width: calc(100% / 10 * 3);
    */
  }
}

.articlequeue__body {
  /*
  padding-left: 15px;
  flex-basis: calc(100% / 10 * 7);
  max-width: calc(100% / 10 * 7);
  */

  @media #{$small-up} {
    margin: 0;
    padding: 0px;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.articlequeue__title {
  color: #222222;
  outline: none;
  margin: 0;
  font-family: $font-family-primary;
  text-align: center;

  @include rem-calc(14);

  line-height: 1.5;
  transition: 200ms color ease-in;

  @media #{$small-up} {
    margin-bottom: .75rem;

    @include rem-calc(16);
  }

  @media #{$large-up} {
    @include rem-calc(18);

    padding: 0;
  }
}

.articlequeue__category {
  color: $archimedict-blue;
  margin: 0 0 5px;

  @include rem-calc(14);

  line-height: 1.5;
  letter-spacing: 0.5px;
  font-weight: 700;
  text-align: center;

  @media #{xsmall-up} {
    display: none;
  }

  @media #{$small-up} {
    margin: 0 0 10px;
  }

  @media #{$medium-up} {
    @include rem-calc(16);
  }
}

.articlequeue__meta {
  display: block;

  @include rem-calc(16);

  font-family: $font-family-secundary;
  color: #222222;
  font-weight: 700;

  @media #{$small-only} {
    display: none;
  }

  @media #{$small-up} {
    display: block;
  }
}

.articlequeue__icon {
  position: absolute;
  z-index: 99999;
  display: block;
  width: 100%;
  height: 100%;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 75%;
    height: 50%;
  }
}

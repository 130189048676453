@media #{$medium-only} {
  .navigation .nav__wrap ul li span {
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .navigation .nav__wrap ul li span {
    transition: color 100ms;
    text-decoration: none;
    text-align: left;
    @media #{$small-up} {
      text-align: center;
    }
  }

  .mainmenu__wrap {
    overflow: visible;
  }

  .navigation {
    display: flex;
    flex-direction: column;
    background: transparent;
  }

  .nav__menu--crossmenu {
    order: 2;
  }

  .nav__menu--mainmenu {
    order: 1;
  }

}

@media #{$large-only} {


  .mainmenu__meta {
    margin-right: 20px;
  }
}

.articlequeue__image {
  figure {
    &.image--is-knvb,
    &.image--is-knvb-knvb {
      background: linear-gradient(to right, #fc9309 50%, #f77c19 100%);
    }

    &.image--is-voetbal {
      background: linear-gradient(to right, #09a770 0, #049754);

      /*margin-top: 45px;*/
    }

    &.image--is-medisch {
      background: linear-gradient(to right, #f5f5f5 0, #cccccc);
    }

    &.image--is-telegraaf {
      background: linear-gradient(to right, #F0F2F3 0, #D2D5D7);
    }

    &.image--is-rinus {
      background: linear-gradient(to right, #70d3f3 0, #5fc9ec);
    }

    &.image--is-sportlink {
      background: linear-gradient(to right, #f2f2f2 0, #F4F8FF);
    }

    &.image--is-medesign {
      background: linear-gradient(to right, #d3cec4 0, #bbb9b5);
    }

    &.image--is-rsu {
      background: linear-gradient(to right, #c0ab72 0, #a79463);
    }

    &.image--is-xs4all {
      background-color: $xs4all-yellow;
    }

    &.image--is-drupal {
      background: linear-gradient(to right, #b7e2ff 0, #0072c0);
    }

    &.image--is-wordpress {
      background: linear-gradient(to right, #016087 0, #00405a);
    }

    &.image--is-scrum {
      background: linear-gradient(to right, #e2efed 0, #cdd4d3);
    }

    &.image--is-design {
      background: linear-gradient(to right, #b2eeff 0, #72e0ff);
    }

    &.image--is-php {
      background: linear-gradient(to right, #7d87b7 0, #455188);
    }

    &.image--is-assistant {
      background: linear-gradient(to right, #f5f5f5 0, #cccccc);
    }

    &.image--is-frontend {
      background: linear-gradient(to right, #f5f5f5 0, #cccccc);
    }

    img {
      display: none;
    }
  }
}

.articlequeue__icon {
  .icon-is-knvb {
    background-image: url("/dist/images/svg/emblemlogo-knvb.svg");
  }

  .icon-is-voetbal {
    background-image: url("/dist/images/svg/emblemlogo-voetbal.svg");
  }

  .icon-is-medisch {
    background-image: url("/dist/images/svg/emblemlogo-medisch.svg");
  }

  .icon-is-telegraaf {
    background-image: url("/dist/images/svg/emblemlogo-telegraaf.svg");
  }

  .icon-is-rinus {
    background-image: url("/dist/images/svg/emblemlogo-rinus.svg");
  }

  .icon-is-sportlink {
    background-image: url("/dist/images/svg/emblemlogo-sportlink.svg");
  }

  .icon-is-medesign {
    background-image: url("/dist/images/svg/emblemlogo-medesign.svg");
  }

  .icon-is-rsu {
    background-image: url("/dist/images/svg/emblemlogo-rsu.svg");
  }

  .icon-is-xs4all {
    background-image: url("/dist/images/svg/emblemlogo-hosting.svg");
  }

  .icon-is-drupal {
    background-image: url("/dist/images/svg/emblemlogo-drupal.svg");
  }

  .icon-is-wordpress {
    background-image: url("/dist/images/svg/emblemlogo-white-wordpress.svg");
  }

  .icon-is-scrum {
    background-image: url("/dist/images/svg/emblemlogo-scrum.svg");
  }

  .icon-is-design {
    background-image: url("/dist/images/svg/emblemlogo-design.svg");
  }

  .icon-is-php {
    background-image: url("/dist/images/svg/emblemlogo-text-only-php.svg");
  }

  .icon-is-assistant {
    background-image: url("/dist/images/svg/emblemlogo-assistant.svg");
  }

  .icon-is-frontend {
    background-image: url("/dist/images/svg/emblemlogo-frontend.svg");
  }
}

.clients .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
  position: relative;

  &.has-border {
    border: solid 1px #ccc;
    padding: 40px 0 20px;
    border-radius: 30px;
  }
}

.clients-label {
  position: absolute;
  top: -16px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  height: auto;
  background-color: #f1f1f1;
  padding: .25rem 2rem;
  z-index: 9;
  white-space: nowrap;
  color: #3f4ace;

  @include rem-calc(14);

  @media #{$small-up} {
    @include rem-calc(18);
  }
}

.clients__item {
  position: relative;
  height: auto;
  text-align: left;
  cursor: default;

  .image {
    transition: all 200ms ease-in;
    height: 100%;
    margin: auto;

    @media #{$small-up} {}
  }

  @media #{$small-only} {
    flex-basis: calc(100% / 3 * 1);
    max-width: calc(100% / 3 * 1);
    padding: 0;
    margin-right: 0px;

    &:nth-last-of-type(-n+2) .clients__image {
      margin-bottom: 0;
    }
  }

  @media #{$small-up} {
    flex-basis: calc(100% / 5 * 1);
    max-width: calc(100% / 5 * 1);
    padding: 0;
    margin-right: 0px;
  }

  &:last-child {
    @media #{$small-up} {
      margin-right: 0px;
    }
  }
}

.clients__image {
  position: relative;
  margin: 0 auto 30px;
  max-width: calc(100% / 3 * 2);
  flex-basis: calc(100% / 3 * 2);

  @media #{$small-up} {
    max-width: calc(100% / 2 * 1);
    flex-basis: calc(100% / 2 * 1);
    margin: 0 auto 0;
  }
}

.clients__body {
  padding-left: 15px;
  flex-basis: calc(100% / 10 * 7);
  max-width: calc(100% / 10 * 7);

  @media #{$small-up} {
    margin: 20px 0 0;
    padding: 0px;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.section--clients .container-header__body p {
  @include rem-calc(14);

  font-family: $font-family-text;
  text-align: center;

  @media #{$small-up} {
    @include rem-calc(16);

    text-align: center;
  }
}

.section--clients .container-header {
  @media #{$small-up} {
    max-width: 100%;
    margin: auto;
  }

  .container-header__title {
    margin-bottom: 30px;
    text-align: center;
  }
}

.section--clients .container-main {
  @media #{$small-up} {
    max-width: 100%;
    margin: auto;
  }
}

.clients .row {
  .clients-label {
    display: none;
  }

  &.has-landscape {
    padding: 30px 0;

    .clients__item {
      flex-basis: calc(100% / 2 * 1);
      max-width: calc(100% / 2 * 1);

      @media #{$small-up} {
        flex-basis: calc(100% / 3 * 1);
        max-width: calc(100% / 3 * 1);
      }

      &:nth-last-of-type(n+3) .clients__image {
        margin-bottom: 0;
      }
    }
  }
}
